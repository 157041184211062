<template>
  <div class="van-main has-fixed-btn">
    <van-tabs v-model="active">
      <van-tab title="实际付款">
        <paymentResults />
      </van-tab>
      <van-tab title="结算单">
        <div class="van-common-detail">
          <paymentStatementPush v-if="settlementForm.statementType === 1" :settlement-detail="settlementForm" type="'payment'" />
          <paymentStatement v-else :detail-tables="settleList" type="payment"/>
        </div>
      </van-tab>
      <van-tab title="相关附件">
        <attachment-list module-name="PAYMENT" :business-id="$route.query.paymentId" />
      </van-tab>
      <van-tab title="审核意见">
        <workflow-audit :task-id="$route.query.taskId" page-key="payment_apply" :business-key="$route.query.orderId" @confirmAudit="utils.backReturn($route.query.messageType)" />
      </van-tab>
    </van-tabs>
    <!--    <back-button />-->
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, Button, Tab, Tabs, Toast, uploader } from 'vant'
import paymentStatement from '@/components/payment/payment-statement'
import AttachmentList from '@/components/attachment-list'
import WorkflowAudit from '@/components/workflow-audit'
import paymentStatementPush from '@/components/payment/payment-pragmatic-push'
import paymentResults from '@/components/payment/payment-results'
// import fileList from '@/components/file-list'
export default {
  components: {
    paymentStatement,
    paymentStatementPush,
    AttachmentList,
    WorkflowAudit,
    paymentResults,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [uploader.name]: uploader
    // fileList
  },
  data () {
    return {
      id: this.$route.query.id,
      active: 0,
      contractForm: {
        signInfoList: [],
        fileDetailInfoVoApply: null,
        fileDetailInfoVoSeal: null
      },
      fileList: [],
      settlementForm: {
        // 关联合同
        contractNumber: '',
        // 付款方类型
        payerType: '',
        // 付款方企业ID
        payerEnterpriseId: '',
        // 收款方类型
        collectionMethodType: '',
        // 收款方企业ID
        beneficiaryEnterpriseId: '',
        // 付款方开户行
        payerAccountBank: '',
        // 收款方开户行
        beneficiaryAccountBank: '',
        // 付款账号
        paymentAccount: '',
        // 收款账号
        accountsReceivable: '',
        // 费用信息
        statementFeeInfoVoList: [],
        // 货款结算明细
        statementCalculationInfoVoList: [],
        // 结算单货物总价信息
        statementCargoPriceInfoVo: {}
      },
      appDownList: [],
      settleList: {
        name: '1111'
      }
    }
  },
  computed: {
  },
  created () {
    // 结算单详情
    this.getStatementDetail()
  },
  methods: {
    getStatementDetail () {
      this.api.collection.statementDetail({ businessid: this.$route.query.collectionId }).then(result => {
        this.settlementForm = this._.assign(this.settlementForm, result.data.value)
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
